<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
          <el-form-item label='商品名称' prop='goodsid' :label-width="formLabelWidth">
            <el-select v-model="form.goodsid" placeholder="请选择" @change="changeSpu">
              <el-option
                      v-for="item in goodsList"
                      :key="item.id"
                      :label="item.goodsname"
                      :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
            <el-form-item label='车辆名称' prop='carname' :label-width="formLabelWidth">
              <el-input placeholder='请输入车辆名称' clearable autocomplete="off" v-model='form.carname' size="small"  />
            </el-form-item>
<!--            <el-form-item label='车辆类型' prop='type' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入车辆类型' clearable autocomplete="off" v-model='form.type' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='车辆国家' prop='country' :label-width="formLabelWidth">
              <el-select v-model="form.country" placeholder="请选择" filterable>
                <el-option
                        v-for="item in countryList"
                        :key="item.twocode"
                        :label="item.shortname"
                        :value="item.twocode">
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label='车辆长度' prop='length' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入车辆长度' clearable autocomplete="off" v-model='form.length' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='已购买位置' prop='position' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入已购买位置' clearable autocomplete="off" v-model='form.position' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='是否自动续费' prop='isauto' :label-width="formLabelWidth">
              <el-select v-model="form.isauto" placeholder="请选择">
                <el-option
                        v-for="item in isOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label='已有车票数' prop='num' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入已有车票数' clearable autocomplete="off" v-model='form.num' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='开始结束时间' prop='startTime' :label-width="formLabelWidth">
              <el-date-picker
                      v-model="form.startTime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间">
              </el-date-picker>
            </el-form-item>
<!--            <el-form-item label='结束时间' prop='endTime' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入结束时间' clearable autocomplete="off" v-model='form.endTime' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='负责人' prop='charge' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入负责人' clearable autocomplete="off" v-model='form.charge' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='是否能购买' prop='isbuy' :label-width="formLabelWidth">
              <el-select v-model="form.isbuy" placeholder="请选择">
                <el-option
                        v-for="item in isOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='车辆状态' prop='status' :label-width="formLabelWidth">
              <el-select v-model="form.status" placeholder="请选择">
                <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
        <!--  已购买位置 仅在点编辑时显示-->
        <el-form-item v-if="type==='update'" label='已购买位置' prop='position' :label-width="formLabelWidth">
          <el-input placeholder='请输入已购买位置' clearable v-model.trim='form.position' size="small"/>
        </el-form-item>
<!--            <el-form-item label='密码更改时间' prop='pwdupdateTime' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入密码更改时间' clearable autocomplete="off" v-model='form.pwdupdateTime' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='单位价格($)' prop='price' :label-width="formLabelWidth">
              <el-input-number placeholder='请输入单位价格' clearable autocomplete="off" v-model='form.price' :precision="2" :step="1" :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label='是否相同账户' prop='sameaccount' :label-width="formLabelWidth">
              <el-select v-model="form.sameaccount" placeholder="请选择" @change="changeAccount">
                <el-option
                      v-for="item in isOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='车辆邮箱' prop='caremail' :label-width="formLabelWidth" v-if="sameaccount&&spu">
              <el-input placeholder='请输入车辆邮箱' clearable autocomplete="off" v-model='form.caremail' size="small"  />
            </el-form-item>
            <el-form-item label='车辆密码' prop='carpwd' :label-width="formLabelWidth" v-if="sameaccount&&spu">
              <el-input placeholder='请输入车辆密码' clearable autocomplete="off" v-model='form.carpwd' size="small"  />
            </el-form-item>
            <el-form-item label='账号密码' :label-width="formLabelWidth" v-if="!sameaccount&&spu&&type=='insert'">
              <template>
                <el-table ref="tablerules" border :data="ticketpwds" style="width: 100%">
                  <el-table-column label="账号" prop="email">
                    <template slot-scope="scope">
                      <el-input placeholder='请输入车辆账号' clearable autocomplete="off" v-model='scope.row.email' size="small"  />
                    </template>
                  </el-table-column>
                  <el-table-column label="密码" prop="password">
                    <template slot-scope="scope">
                      <el-input placeholder='请输入车辆密码' clearable autocomplete="off" v-model='scope.row.password' size="small"  />
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="重置密码" :visible.sync="dialogFormVisiblePwd" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialogPwd">
      <el-form :model="formPwd" :rules="rulesPwd" ref="ruleUserPwd">
        <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
          <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='formPwd.id' size="small"  />
        </el-form-item>
        <el-form-item label='车辆邮箱' prop='caremail' :label-width="formLabelWidth">
          <el-input placeholder='请输入车辆邮箱' clearable autocomplete="off" v-model='formPwd.caremail' size="small"  />
        </el-form-item>
        <el-form-item label='车辆密码' prop='carpwd' :label-width="formLabelWidth">
          <el-input placeholder='请输入车辆密码' clearable autocomplete="off" v-model='formPwd.carpwd' size="small"  />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialogPwd()">取 消</el-button>
        <el-button type="primary" @click="submitUserPwd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertCarinfo,updateCarinfo,deleteCarinfo,bacthDelCarinfo,getCountryinfoAll,getGoodsSpuList,carinfoUpdPwd,getCarinfoList } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  import {
    isNumberComma,
    isCommonStart,
    isCommonEnd
  } from "@/util/validate";
  export default {
    name: "user",
    data(){
      var validateNumberComma = (rule, value, callback) => {
        if(!isNumberComma(value)){
          callback(new Error('只支持输入数字和半角逗号'));
        }
        callback();
      }
      var validateIsCommonStart = (rule, value, callback) => {
        if(!isCommonStart(value)){
          callback(new Error('不能以半角逗号开头'));
        }
        callback();
      }
      var validateIsCommonEnd = (rule, value, callback) => {
        if(!isCommonEnd(value)){
          callback(new Error('不能以半角逗号结尾'));
        }
        callback();
      }
      const typeOptions=[
        {
          value:"1",
          label:"共享"
        },
        {
          value:"2",
          label:"独享"
        },
      ]
      const isOptions=[
        {
          value:"1",
          label:"是",
        },
        {
          value:"0",
          label:"否",
        },
      ]
      const statusOptions=[
        {
          value:"1",
          label:"上线",
        },
        {
          value:"0",
          label:"下线",
        },
      ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'carname',label: '车辆名称'},
          // {prop: 'type',label: '车辆类型'},
          {prop: 'country',label: '车辆国家'},
          {prop: 'length',label: '车辆长度'},
          {prop: 'position',label: '已购买位置'},
          {prop: 'isauto',label: '是否自动续费',formatter: row => this.isautoState(row)},
          {prop: 'num',label: '已有车票数'},
          {prop: 'goodsid',label: '商品id'},
          {prop: 'goodsSpu.goodsname',label: '商品名称'},
          {prop: 'goodsSpu.type',label: '售卖类型',formatter: row => this.typeState(row)},
          {prop: 'startTime',label: '开始时间'},
          {prop: 'endTime',label: '结束时间'},
          {prop: 'charge',label: '负责人'},
          {prop: 'isbuy',label: '是否能购买',formatter: row => this.isbuyState(row)},
          {prop: 'status',label: '车辆状态',formatter: row => this.statusState(row)},
          {prop: 'sameaccount',label: '是否相同账户',formatter: row => this.sameState(row)},
          {prop: 'pwdupdateTime',label: '密码更改时间'},
          {prop: 'price',label: '单位价格'},
          {prop: 'caremail',label: '车辆邮箱'},
          {prop: 'carpwd',label: '车辆密码'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
        {
          type: 'date-picker',
          id: 'date',
          label: '时间筛选',
          el: {
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd'
          },
          inputFormat: (row) => {
            if (row.startdate && row.enddate) {
              return [row.startdate, row.enddate]
            }
          },
          outputFormat: (val) => {
            if (!val) {
              return ""
            }
            return {
              startdate: val[0],
              enddate: val[1]
            }
          }
        },
        {
          type: 'select',
          id: 'goodsid',
          label: '商品名称',
          el: {placeholder: '请选择', clearable: true},
          options:this.spuList
        },
          {
            type: 'input',
            id: 'carname',
            label: '车辆名称',
            el: {placeholder: '请输入车辆名称', clearable: true},
          },
          {
            type: 'input',
            id: 'country',
            label: '车辆国家',
            el: {placeholder: '请输入车辆国家', clearable: true},
          },
          {
            type: 'select',
            id: 'isauto',
            label: '是否自动续费',
            el: {placeholder: '请输入是否自动续费', clearable: true},
            options:isOptions
          },
          {
            type: 'input',
            id: 'charge',
            label: '负责人',
            el: {placeholder: '请输入负责人', clearable: true},
          },
          {
            type: 'select',
            id: 'isbuy',
            label: '是否能购买',
            el: {placeholder: '请输入是否能购买', clearable: true},
            options:isOptions
          },
          {
            type: 'input',
            id: 'status',
            label: '车辆状态',
            el: {placeholder: '请输入车辆状态', clearable: true},
          },
          {
            type: 'input',
            id: 'caremail',
            label: '车辆邮箱',
            el: {placeholder: '请输入车辆邮箱', clearable: true},
          },
          {
            type: 'input',
            id: 'carpwd',
            label: '车辆密码',
            el: {placeholder: '请输入车辆密码', clearable: true},
          },
      ]
      return {
        columns,
        isOptions,
        statusOptions,
        typeOptions,
        userinfo:{},
        rowTemp:{
          id:''
        },
        countryList:[],
        goodsList:[],
        spuList:[],
        tableConfig: {
          searchForm,
          url: 'carinfo/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('carinfo:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('carinfo:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '修改密码',
              icon: 'el-icon-edit',
              show:(row)=>{
                if (row.sameaccount=="1"&&this.hasRole('carinfo:updPwd')){
                  return true
                }else {
                  return false
                }
              },
              atClick: async row => {
                this.handleUpdPwd(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('carinfo:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('carinfo:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        formPwd:this.initFormPwd(),
        title:"",
        type:"",
        dialogFormVisible:false,
        dialogFormVisiblePwd:false,
        formLabelWidth:"110px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            carname :[
              {required: true, message: '请输入车辆名称', trigger: 'blur'},],
            type :[
              {required: true, message: '请输入车辆类型', trigger: 'blur'},],
            country :[
              {required: true, message: '请输入车辆国家', trigger: 'blur'},],
            length :[
              {required: true, message: '请输入车辆长度', trigger: 'blur'},],
            position :[
              {required: true, message: '请输入已购买位置', trigger: 'blur'},
              {validator: validateNumberComma, trigger: ['blur','change']},
              {validator: validateIsCommonStart, trigger: ['blur','change']},
              {validator: validateIsCommonEnd, trigger: ['blur','change']},
            ],
            isauto :[
              {required: true, message: '请输入是否自动续费', trigger: 'blur'},],
            num :[
              {required: true, message: '请输入已有车票数', trigger: 'blur'},],
            goodsid :[
              {required: true, message: '请选择商品', trigger: 'blur'},],
            startTime :[
              {required: true, message: '请输入开始时间', trigger: 'blur'},],
            endTime :[
              {required: true, message: '请输入结束时间', trigger: 'blur'},],
            charge :[
              {required: true, message: '请输入负责人', trigger: 'blur'},],
            isbuy :[
              {required: true, message: '请输入是否能购买', trigger: 'blur'},],
            status :[
              {required: true, message: '请输入车辆状态', trigger: 'blur'},],
            pwdupdateTime :[
              {required: true, message: '请输入密码更改时间', trigger: 'blur'},],
            sameaccount:[
              {required: true, message: '请选择是否相同账户', trigger: 'blur'},],
            price :[
              {required: true, message: '请输入单位价格', trigger: 'blur'},],
            caremail :[
              {required: true, message: '请输入车辆邮箱', trigger: 'blur'},],
            carpwd :[
              {required: true, message: '请输入车辆密码', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
        rulesPwd: {
          id :[
            {required: true, message: '请输入序号', trigger: 'blur'},],
          caremail :[
            {required: true, message: '请输入车辆邮箱', trigger: 'blur'},],
          carpwd :[
            {required: true, message: '请输入车辆密码', trigger: 'blur'},],
        },
        sameaccount:true,
        ticketpwds:[],
        spu:""
      }
    },
    methods:{
      changeSpu(){
        this.goodsList.forEach(r=>{
          if (r.id==this.form.goodsid){
            this.spu=r
            this.changeAccount()
          }
        })
      },
      changeAccount(){
        this.ticketpwds=[]
        if (this.form.sameaccount=='1'){
          this.sameaccount=true
        }

        if (this.form.sameaccount=='0'){
          this.sameaccount=false
          this.form.caremail=""
          this.form.carpwd=""
          // if (this.spu.type=='1'){
          //   for (let i = 0; i < this.spu.maxnum; i++) {
          //     this.ticketpwds.push({
          //       email:"",
          //       password:""
          //     })
          //   }
          // }
          // if (this.spu.type=='2'){
          //   this.ticketpwds.push({
          //     email:"",
          //     password:""
          //   })
          // }
          for (let i = 0; i < this.spu.maxnum; i++) {
            this.ticketpwds.push({
              email:"",
              password:""
            })
          }
        }
      },
      typeState(row){
        let data
        this.typeOptions.forEach(r=>{
          if (r.value==row.goodsSpu.type){
            data=r.label
          }
        })
        return data
      },
      isautoState(row){
        let data
        this.isOptions.forEach(r=>{
          if (r.value==row.isauto){
            data=r.label
          }
        })
        return data
      },
      isbuyState(row){
        let data
        this.isOptions.forEach(r=>{
          if (r.value==row.isbuy){
            data=r.label
          }
        })
        return data
      },
      statusState(row){
        let data
        this.statusOptions.forEach(r=>{
          if (r.value==row.status){
            data=r.label
          }
        })
        return data
      },
      sameState(row){
        let data
        this.isOptions.forEach(r=>{
          if (r.value==row.sameaccount){
            data=r.label
          }
        })
        return data
      },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          carname : "", // 车辆名称
          type : "", // 车辆类型
          country : "", // 车辆国家
          length : "", // 车辆长度
          position : "", // 已购买位置
          isauto : "", // 是否自动续费
          num : "", // 已有车票数
          goodsid : "", // 商品id
          startTime : "", // 开始时间
          endTime : "", // 结束时间
          charge : "", // 负责人
          isbuy : "", // 是否能购买
          status : "", // 车辆状态
          pwdupdateTime : "", // 密码更改时间
          sameaccount:"", // 是否相同账户
          price : "", // 单位价格
          caremail : "", // 车辆邮箱
          carpwd : "", // 车辆密码
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      initFormPwd() {//初始数据
        return {
          id : "", // 序号
          carname : "", // 车辆名称
          type : "", // 车辆类型
          country : "", // 车辆国家
          length : "", // 车辆长度
          position : "", // 已购买位置
          isauto : "", // 是否自动续费
          num : "", // 已有车票数
          goodsid : "", // 商品id
          startTime : "", // 开始时间
          endTime : "", // 结束时间
          charge : "", // 负责人
          isbuy : "", // 是否能购买
          status : "", // 车辆状态
          pwdupdateTime : "", // 密码更改时间
          sameaccount:"", // 是否相同账户
          price : "", // 单位价格
          caremail : "", // 车辆邮箱
          carpwd : "", // 车辆密码
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        getCarinfoList(row.id).then((row)=> {
          // row.goodsSpu = {}
          this.form = row.data.list[0]
          this.form.startTime=[this.form.startTime,this.form.endTime]
          this.openDialog();
          this.title = "修改信息"
          this.type = "update"
          this.changeSpu()
        })
      },
      handleUpdPwd(row){
        row.goodsSpu = {}
        this.formPwd = row
        this.dialogFormVisiblePwd=true;
      },
      submitUserPwd(){
        this.$confirmel('此操作将重置此车辆的所有密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.operatorUpdPwd()
        }).catch(() => {
          MessageInfo("已取消");
        });
      },
      async operatorUpdPwd() {
        this.$refs.ruleUserPwd.validate((valid) => {
          if (valid) {
            this.operatorUp()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUp() {
        let res = await carinfoUpdPwd(this.formPwd)
        if (res.status == "200") {
          MessageSuccess(res.msg)
          this.formPwd = this.initFormPwd()
          this.dialogFormVisiblePwd = false;
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteCarinfo(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelCarinfo(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      closeDialogPwd() {
        this.dialogFormVisiblePwd = false
        this.formPwd = this.initFormPwd();
        this.$refs.ruleUserPwd.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        this.form.endTime=this.form.startTime[1]
        this.form.startTime=this.form.startTime[0]
        if (this.type == "insert") {
          if (this.form.sameaccount=='0'){
            for (let i = 0; i < this.ticketpwds.length; i++) {
              if (""==this.ticketpwds[i].email.trim()||""==this.ticketpwds[i].password.trim()){
                MessageError("所有账号密码不能为空")
                return
              }
            }
            this.form.ticketpwds=this.ticketpwds
          }
          if (this.form.sameaccount=='1'){
            this.form.ticketpwds=[]
          }
          res = await insertCarinfo(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateCarinfo(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getCountryinfoAllInfo() {
            let res = await getCountryinfoAll()
            if (res.status == "200") {
                this.countryList = res.data
            } else {
                MessageError(res.msg)
            }
      },
      async getGoodsSpuListInfo() {
        let res = await getGoodsSpuList()
        if (res.status == "200") {
          this.goodsList = res.data.list
          res.data.list.forEach(r=>{
            let data={
              value:r.id,
              label:r.goodsname
            }
            this.spuList.push(data)
          })
          this.tableConfig.searchForm[1].options=this.spuList
        } else {
          MessageError(res.msg)
        }
      },
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('carinfo:update')&&!this.hasRole('carinfo:delete')){
          this.tableConfig.hasOperation=false
        }
        this.getCountryinfoAllInfo()
        this.getGoodsSpuListInfo()
      }
    }
  }
</script>

<style scoped>

</style>
