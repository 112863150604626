
export function isEmail(s) {
    return /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6})$/.test(s)
}

export function isPassword(s) {
    //密码包含大写字母，数字，特殊字符，三者任意组合   长度限制 8-16位
    // return /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/.test(s)
    //密码包含大写字母，数字，特殊字符，三者任意组合
    // return /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)/.test(s)
    //密码包含任意字母，数字，特殊字符
    return /^[a-zA-Z0-9_!@#$%^&*`~()-+=]+$/.test(s)
}

export function isNumber(s) {
    return /^[0-9]*$/.test(s)
}

export function isNumberComma(s){
    return /^[0-9,]*$/.test(s)
}

export function isCommonStart(s){
    return /^(?![,])/.test(s)
}

export function isCommonEnd(s){
    return /(?<![,])$/.test(s)
}